import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
const Privacy = lazy(() => import("./Components/Ar/Policy"));
const LandingPage = lazy(() => import("./Components/LandingPage/LandingPage"));
const LandingPageEN = lazy(() =>
  import("./Components/LandingPageEN/LandingPage")
);
const Header = lazy(() => import("./Layout/Ar/Header"));
const HeaderEN = lazy(() => import("./Layout/EN/Header"));

const calculatorRoutes = [
  {
    // Gregorian age Arabic
    path: "/calculator/ar/agegregorian/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/AgeCalculatorGregorian.js")
    ),
  },
  {
    // Gregorian age Arabic
    path: "/calculator/ar/agehijri/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/AgeCalculatorHijri.js")
    ),
  },
  {
    // Gregorian age Arabic
    path: "/calculator/ar/age/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/AgeCalculator.js")
    ),
  },
  {
    path: "/en/",
    component: lazy(() => import("./Components/LandingPageEN/LandingPage")),
  },
  {
    path: "/en/all-calculators",
    component: lazy(() => import("./Components/En/All-Calculators.js")),
  },
  {
    // ConversionToHijri
    path: "/calculator/ar/gregorian_to_hijri/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/ConversionToHijri.js")
    ),
  },
  {
    // ConversionToGregorian
    path: "/calculator/ar/hijri_to_gregorian/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/ConversionToGregorian.js")
    ),
  },
  {
    // Gregorian age differences Arabic
    path: "/calculator/ar/gregoriandifferences/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/AgeDifferencesGregorian")
    ),
  },
  {
    // Gregorian date differences Arabic
    path: "/calculator/ar/dategregoriandifferences/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/DateDifferencesGregorian")
    ),
  },
  {
    // Gregorian age differences Arabic
    path: "/calculator/ar/datehijridifferences/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/DateDifferencesHijri")
    ),
  },
  {
    // birthday Arabic
    path: "/calculator/ar/birthday/",
    component: lazy(() => import("./Components/Ar/HumanCalculator/BirthDay")),
  },
  {
    // BMI Calculator adultes Arabic
    path: "/calculator/ar/bmiadults/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BMICalculator")
    ),
  },
  {
    // BMI Calculator children Arabic
    path: "/calculator/ar/bmichildren/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BMIChildCalculator")
    ),
  },
  {
    // calories Calculator Arabic
    path: "/calculator/ar/calories/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/CaloriesCalculator")
    ),
  },
  {
    // bmr Calculator Arabic
    path: "/calculator/ar/bmr/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BMRCalculator")
    ),
  },
  {
    // idealweight Calculator Arabic
    path: "/calculator/ar/idealweight/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/IdealWeightCalculator")
    ),
  },
  {
    // bodyfat Calculator Arabic
    path: "/calculator/ar/bodyfat/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BodyFatCalculator")
    ),
  },
  {
    // carbohydrate Calculator Arabic
    path: "/calculator/ar/carbohydrate/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/CarbohydrateCalculator")
    ),
  },
  {
    // foodenergy Calculator Arabic
    path: "/calculator/ar/foodenergy/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/FoodEnergyCalculator")
    ),
  },
  {
    // Protein Calculator Arabic
    path: "/calculator/ar/protein/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/ProteinCalculator")
    ),
  },
  {
    // Protein Calculator Arabic
    path: "/calculator/en/protein/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/ProteinCalculator")
    ),
  },
  {
    // Money Zakat Calculator Arabic
    path: "/calculator/ar/money-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/MoneyZakatCalculator")
    ),
  },
  {
    // Gold Calculator Arabic
    path: "/calculator/ar/gold-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/GoldZakatCalculator")
    ),
  },
  {
    // Sliver Calculator Arabic
    path: "/calculator/ar/silver-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/SilverZakatCalculator")
    ),
  },
  {
    // Fruites Calculator Arabic
    path: "/calculator/ar/fruits-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/FruitsZakatCalculator")
    ),
  },
  {
    // Trade Calculator Arabic
    path: "/calculator/ar/trade-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/TradeZakatCalculator")
    ),
  },
  {
    // Animal Calculator Arabic
    path: "/calculator/ar/animals-zakat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/AnimalZakatCalculator")
    ),
  },
  {
    // Gold Karat Calculator Arabic
    path: "/calculator/ar/gold-karat/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/GoldKaratCalculator")
    ),
  },
  {
    // PeriodCalculator Arabic
    path: "/calculator/ar/period/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/PeriodCalculator")
    ),
  },
  {
    // foodenergy Calculator Arabic
    path: "/calculator/ar/conception/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/ConceptionCalculator")
    ),
  },
  {
    // OvulationCalculator Arabic
    path: "/calculator/ar/ovulation/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/OvulationCalculator")
    ),
  },
  {
    // DueDateCalculator Arabic
    path: "/calculator/ar/pregnancy-due-date/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/DueDateCalculator")
    ),
  },
  {
    // PregnancyConceptionCalculator Arabic
    path: "/calculator/ar/pregnancy-conception",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/PregnancyConceptionCalculator")
    ),
  },
  {
    // PregnancyCalculator Arabic
    path: "/calculator/ar/pregnancy/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/PregnancyCalculator")
    ),
  },
  {
    // Bra Size Calculator Arabic
    path: "/calculator/ar/bra_size_calculator/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/BraSizeCalculator")
    ),
  },
  {
    //about
    path: "/ar/about/",
    component: lazy(() => import("./Components/Ar/About")),
  },
  {
    //Advertise
    path: "/ar/advertise/",
    component: lazy(() => import("./Components/Ar/Advertise")),
  },
  {
    //percentage
    path: "/calculator/ar/percentage/",
    component: lazy(() => import("./Components/Ar/MathCalculator/Percentage")),
  },
  {
    // Roots Calculator
    path: "/calculator/ar/roots/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/RootCalculator")
    ),
  },
  {
    // Roots Calculator
    path: "/calculator/en/roots/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/RootCalculator")
    ),
  },
  {
    // Triangle Calculator
    path: "/calculator/ar/triangle/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/TriangleCalculator.js")
    ),
  },
  // {
  //   // Inheritances Calculator
  //   path: "/calculator/ar/Inheritances/",
  //   component: lazy(() =>
  //     import("./Components/Ar/IslamicCalculator/InheritancesCalculator.js")
  //   ),
  // },
  {
    // SleapCalculator Ar
    path: "/calculator/ar/sleep/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/SleapCalculator.js")
    ),
  },
  {
    // SleapCalculator en
    path: "/calculator/en/sleep/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/SleapCalculator.js")
    ),
  },
  {
    path: "/calculator/ar/horsepower/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/EngineHorsepower")
    ),
  },
  {
    path: "/calculator/en/horsepower/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/EngineHorsepower")
    ),
  },
  {
    // PasswordGenerator
    path: "/calculator/ar/random-password-generator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/PasswordGenerator")
    ),
  },
  {
    // PasswordGenerator
    path: "/calculator/ar/internet-speed/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/InternetSpeed")
    ),
  },
  {
    // PasswordGenerator
    path: "/calculator/en/random-password-generator/",
    component: lazy(() =>
      import("./Components/En/OtherCalculator/PasswordGenerator")
    ),
  },
  {
    // NameGenerator
    path: "/calculator/ar/random-name-generator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/NameGenerator")
    ),
  },
  {
    // Random Number Generator English
    path: "/calculator/en/random-name-generator/",
    component: lazy(() =>
      import("./Components/En/OtherCalculator/NameGenerator")
    ),
  },
  {
    // Random Number Generator Arabic
    path: "/calculator/ar/random-number-generator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/RandomNumberGenerator")
    ),
  },
  {
    // Text decorator Arabic
    path: "/calculator/ar/text-decorator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/TextDecorator")
    ),
  },
  {
    // Days Calculator Arabic
    path: "/calculator/ar/days-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/DaysCalculator")
    ),
  },
  {
    // Fuel cost Calculator Arabic
    path: "/calculator/ar/fuel-cost-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/FuelCostCalculator")
    ),
  },
  {
    // Discount Calculator Arabic
    path: "/calculator/ar/discount-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/DiscountCalculator")
    ),
  },
  {
    // Battery life Calculator Arabic
    path: "/calculator/ar/battery-life-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/BatteryLifeCalculator")
    ),
  },
  {
    // Height Difference Calculator Arabic
    path: "/calculator/ar/height-difference-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/HeightDifferenceCalculator")
    ),
  },
  {
    // Hourly Salary Calculator Arabic
    path: "/calculator/ar/hourly-salary-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/HourlySalaryCalculator")
    ),
  },
  {
    // Daily Salary Calculator Arabic
    path: "/calculator/ar/daily-salary-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/DailySalaryCalculator")
    ),
  },
  {
    // Daily Salary Calculator Arabic
    path: "/calculator/ar/template-calculator/",
    component: lazy(() => import("./Components/Ar/Template")),
  },
  {
    // Word Counter
    path: "/calculator/ar/word-counter/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/WordCounter")
    ),
  },
  {
    // currency spreader
    path: "/calculator/ar/currency-spreader/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/CurrencySpreading")
    ),
  },
  {
    // matrix calculator
    path: "/calculator/ar/matrix-operations/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/MatrixOperations")
    ),
  },
  {
    // Solar calculator
    path: "/calculator/ar/solar-calculator/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/SolarCalculator")
    ),
  },
  {
    // countries list
    path: "/calculator/ar/countries/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/CountriesList")
    ),
  },
  {
    // calculators list for each country
    path: "/calculator/ar/countries/:fromParams/calculators-list/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/countryCalculators")
    ),
  },
  {
    // preparatory year calculator
    path: "/calculator/ar/countries/SY/calculators-list/preparatory-year-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/PreparatoryYearCalculator")
    ),
  },
  {
    // overtime calculator
    path: "/calculator/ar/countries/SA/calculators-list/overtime-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/OvertimeCalculator")
    ),
  },
  {
    // food nutrients calculator
    path: "/calculator/ar/food-nutrients-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/FoodNutrientsCalculator")
    ),
  },
  {
    // BAI calculator
    path: "/calculator/ar/bai-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BAICalculator")
    ),
  },
  {
    // Face Shape calculator
    path: "/calculator/ar/face-shape-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/FaceShapeCalculator")
    ),
  },
  {
    // Blood sugar calculator
    path: "/calculator/ar/blood-sugar-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BloodSugarCalculator")
    ),
  },
  {
    // Diabetes Risk calculator
    path: "/calculator/ar/diabetes-risk-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/DiabetesRiskCalculator")
    ),
  },
  {
    // estimated-average-glucose-calculator
    path: "/calculator/ar/estimated-average-glucose-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/GlucoseCalculator")
    ),
  },
  {
    // English learning time Calculator
    path: "/calculator/ar/english-learning-time-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/EnglishLearningTimeCalculator")
    ),
  },
  {
    // Tally counter
    path: "/calculator/ar/tally-counter/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/TallyCounter")
    ),
  },
  {
    // Arabic to English Number converter
    path: "/calculator/ar/arabic-to-english/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/ArabicToEnglishConverter.js")
    ),
  },
  {
    // Numbers to words converter
    path: "/calculator/ar/numbers-to-words/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/NumbersToWordsConverter.js")
    ),
  },
  {
    // Fractions calculator
    path: "/calculator/ar/fractions-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/FractionsCalculator.js")
    ),
  },
  {
    //simplifying Fractions calculator
    path: "/calculator/ar/simplify-fractions/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/SimplifyingFractionsCalculator.js")
    ),
  },
  {
    //Medicine Dosage calculator
    path: "/calculator/ar/medicine-dosage-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/MedicineDosageCalculator.js")
    ),
  },
  {
    //Children Paracetamol Dosage calculator
    path: "/calculator/ar/children-paracetamol-dosage-calculator/",
    component: lazy(() =>
      import(
        "./Components/Ar/HealthCalculator/ParacetamolChildrenDosageCalculator.js"
      )
    ),
  },
  {
    //Profit Margin calculator
    path: "/calculator/ar/profit-margin-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/ProfitMarginCalculator.js")
    ),
  },
  {
    //German Learning time calculator
    path: "/calculator/ar/german-learning-time-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/GermanLearningTimeCalculator.js")
    ),
  },
  {
    //French Learning time calculator
    path: "/calculator/ar/french-learning-time-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/FrenchLearningTimeCalculator.js")
    ),
  },
  {
    //Absence calculator
    path: "/calculator/ar/absence-calculator/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/AbsenceCalculator.js")
    ),
  },
  {
    //Absence calculator
    path: "/calculator/ar/eye-test/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/EyeTest.js")
    ),
  },
  {
    //weighted percentage calculator
    path: "/calculator/ar/weighted-percentage-calculator/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/WeightedPercentageCalculator.js")
    ),
  },
  {
    //GFR calculator
    path: "/calculator/ar/gfr-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/GFRCalculator.js")
    ),
  },
  {
    //Liter calculator
    path: "/calculator/ar/liter-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/LiterConverter.js")
    ),
  },
  {
    //Two thirds calculator
    path: "/calculator/ar/twothirds-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/TwoThirdsCalculator.js")
    ),
  },
  {
    //solubility calculator
    path: "/calculator/ar/solubility-calculator/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/SolubilityCalculator.js")
    ),
  },
  {
    //triangle angle calculator
    path: "/calculator/ar/triangle-angle-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/TriangleAnglesCalculator.js")
    ),
  },
  {
    //Water density calculator
    path: "/calculator/ar/water-density-calculator/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/WaterDensityCalculator.js")
    ),
  },
  {
    //Molecular weight calculator
    path: "/calculator/ar/molecular-weight-calculator/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/MolecularWeightCalculator.js")
    ),
  },
  {
    //Transformer capacity calculator
    path: "/calculator/ar/transformer-capacity-calculator/",
    component: lazy(() =>
      import(
        "./Components/Ar/PhysicsCalculator/TransformerCapacityCalculator.js"
      )
    ),
  },
  {
    //Square diamter calculator
    path: "/calculator/ar/square-diameter-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/SquareDiameterCalculator.js")
    ),
  },
  {
    //Triangle Hypotenuse calculator
    path: "/calculator/ar/hypotenuse-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/TriangleHypotenuseCalculator.js")
    ),
  },
  {
    //Salary Bonus calculator
    path: "/calculator/ar/salary-bonus-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/SalaryBonusCalculator.js")
    ),
  },
  {
    //Heartbeat calculator
    path: "/calculator/ar/heartbeat-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/HeartbeatCalculator.js")
    ),
  },
  {
    //Addiction calculator
    path: "/calculator/ar/addiction-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/AddictionCalculator.js")
    ),
  },
  {
    //Smoking Recovery calculator
    path: "/calculator/ar/smoking-recovery-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/SmokingRecoveryCalculator.js")
    ),
  },
  {
    //Cholesterol Ratio calculator
    path: "/calculator/ar/cholesterol-ratio-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/CholesterolRatioCalculator.js")
    ),
  },
  {
    //QR Code converter
    path: "/calculator/ar/qr-code/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/LinkToQRCodeConverter.js")
    ),
  },
  {
    // English to Arabic Number converter
    path: "/calculator/ar/english-to-arabic/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/EnglishToArabicConverter.js")
    ),
  },
  {
    // Mean Median mode range Generator Arabic
    path: "/calculator/ar/mean-median-mode-range-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/Mean-Median-Mode-RangeCalculator")
    ),
  },
  {
    // Volume Calculator
    path: "/calculator/ar/volumes/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/VolumesCalculator")
    ),
  },
  {
    // Volume Calculator
    path: "/calculator/en/volumes/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/VolumesCalculator")
    ),
  },
  {
    // Area Calculator
    path: "/calculator/ar/areas/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/AreaCalculators")
    ),
  },
  {
    // Area Calculator
    path: "/calculator/en/areas/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/AreaCalculators")
    ),
  },
  {
    // Median
    path: "/calculator/ar/median/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/MedianCalculator")
    ),
  },
  {
    //gpa 4
    path: "/calculator/ar/gpa4/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/GPA4")),
  },
  {
    //gpa 5
    path: "/calculator/ar/gpa5/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/GPA5")),
  },
  {
    //grade100
    path: "/calculator/ar/grade100/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/Grade100")),
  },
  {
    //grade100to4
    path: "/calculator/ar/grade100to4/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/Grade100to4")
    ),
  },
  {
    //grade100to5
    path: "/calculator/ar/grade100to5/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/Grade100to5")
    ),
  },
  {
    //gpa4to5
    path: "/calculator/ar/gpa4to5/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/GPA4to5")),
  },
  {
    //test grade
    path: "/calculator/ar/test-grade/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/TestGrade")),
  },
  {
    //uk grade
    path: "/calculator/ar/gradetouk/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/GradeToUk")),
  },
  {
    //german grade
    path: "/calculator/ar/gradetogerman/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToGerman")
    ),
  },
  {
    //french grade
    path: "/calculator/ar/gradetofrench/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToFrench")
    ),
  },
  {
    //canada grade
    path: "/calculator/ar/gradetocanada/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToCanada")
    ),
  },
  {
    //malysia grade
    path: "/calculator/ar/gradetomalaysia/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToMalysia")
    ),
  },
  {
    //turkey grade
    path: "/calculator/ar/gradetoturkey/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToTurkey")
    ),
  },
  {
    //turkey grade
    path: "/calculator/ar/gradetoaustralia/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeToAustralia")
    ),
  },
  {
    //GPA Planner
    path: "/calculator/ar/gpaplanner/",
    component: lazy(() => import("./Components/Ar/StudyCalculator/GPAPlanner")),
  },
  {
    // GPA Grade Calculator - official
    path: "/calculator/ar/gpagrade/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GradeGPACalculator")
    ),
  },
  {
    // GPA Table
    path: "/calculator/ar/gpatableconverter/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/GPATabelConverter")
    ),
  },

  // Others (Arbic)
  {
    // 5%
    path: "/calculator/ar/5percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/5percent")),
  },
  {
    // 10%
    path: "/calculator/ar/10percent",
    component: lazy(() => import("./Components/Ar/MathCalculator/10percent")),
  },
  {
    // 15%
    path: "/calculator/ar/15percent",
    component: lazy(() => import("./Components/Ar/MathCalculator/15percent")),
  },
  {
    // 20%
    path: "/calculator/ar/20percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/20percent")),
  },
  {
    // 25%
    path: "/calculator/ar/25percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/25percent")),
  },
  {
    // 30%
    path: "/calculator/ar/30percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/30percent")),
  },
  {
    // 35%
    path: "/calculator/ar/35percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/35percent")),
  },
  {
    // 40%
    path: "/calculator/ar/40percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/40percent")),
  },
  {
    // 45%
    path: "/calculator/ar/45percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/45percent")),
  },
  {
    // 75%
    path: "/calculator/ar/75percent/",
    component: lazy(() => import("./Components/Ar/MathCalculator/75percent")),
  },
  {
    // ******* physics calculators *******
    // LensPowerCalculator
    path: "/calculator/ar/lenspower/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/LensPowerCalculator")
    ),
  },
  {
    // CubicMeterCalculator
    path: "/calculator/ar/cubicmeter/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/CubicMeterCalculator")
    ),
  },
  {
    // GasVolumeCalculator
    path: "/calculator/ar/gasvolume/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/GasVolumeCalculator.js")
    ),
  },
  {
    // Ion Charge Calculator
    path: "/calculator/ar/ion-charge-calculator/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/IonChargeCalculator.js")
    ),
  },
  {
    // Dew Point Calculator
    path: "/calculator/ar/dew-point-calculator/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/DewPointCalculator.js")
    ),
  },
  {
    // arrival time
    path: "/calculator/ar/arrivaltime/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/ArrivalTime")
    ),
  },
  {
    // kineticenergy
    path: "/calculator/ar/kineticenergy/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/KineticEnergy")
    ),
  },
  {
    // potentialenergy
    path: "/calculator/ar/potentialenergy/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/PotentialEnergy")
    ),
  },
  {
    // electricalenergy
    path: "/calculator/ar/electricalenergy/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/ElectricalEnergy")
    ),
  },
  {
    // oam
    path: "/calculator/ar/ohmslaw/",
    component: lazy(() => import("./Components/Ar/PhysicsCalculator/OhmsLaw")),
  },
  {
    // wave
    path: "/calculator/ar/wave/",
    component: lazy(() => import("./Components/Ar/PhysicsCalculator/Wave")),
  },
  {
    // pressure
    path: "/calculator/ar/pressure/",
    component: lazy(() => import("./Components/Ar/PhysicsCalculator/Pressure")),
  },
  {
    // resistor
    path: "/calculator/ar/resistor-calculator/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/ResistorCalculator")
    ),
  },
  {
    // Pe-ratio
    path: "/calculator/ar/pe-ratio/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/Pe-ratio")
    ),
  },
  {
    // GoogleAdsene
    path: "/calculator/ar/google-adsense/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/GoogleAdseneCalculator")
    ),
  },

  {
    // tank area
    path: "/calculator/ar/tank-area/",
    component: lazy(() => import("./Components/Ar/MathCalculator/TankArea")),
  },
  {
    // Absence Percentage
    path: "/calculator/ar/absence-percentage/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/AbsencePercentage")
    ),
  },
  {
    // attendance Percentage
    path: "/calculator/ar/attendance-percentage/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/AttendancePercentage")
    ),
  },
  {
    //  WaterMass
    path: "/calculator/ar/water-mass/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/WaterMass")
    ),
  },
  {
    //  Sample Size Calculator
    path: "/calculator/ar/sample-size-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/SampleSizeCalculator.js")
    ),
  },
  {
    //  Trapezoid Height Calculator
    path: "/calculator/ar/trapezoid-height-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/TrapezoidHeightCalculator.js")
    ),
  },
  {
    //  Remainder Calculator
    path: "/calculator/ar/remainder-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/RemainderCalculator.js")
    ),
  },
  {
    //  Earth Mass Calculator
    path: "/calculator/ar/earth-mass-calculator/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/EarthMassCalculator.js")
    ),
  },
  {
    //  Population Density
    path: "/calculator/ar/population-density/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/PopulationDensity")
    ),
  },
  {
    //    one-fifth
    path: "/calculator/ar/one-fifth/",
    component: lazy(() => import("./Components/Ar/MathCalculator/OneFifth")),
  },
  {
    // cylinder-circumference
    path: "/calculator/ar/cylinder-circumference/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/cylinder-circumference")
    ),
  },
  {
    // Chemistry Calculators List
    path: "/ar/chemistry-calculators/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/ChemistryCalculatorsList")
    ),
  },
  {
    // iron-mass
    path: "/calculator/ar/iron-mass/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/iron-mass")
    ),
  },
  {
    // molar-mass
    path: "/calculator/ar/molar-mass-calculator/",
    component: lazy(() =>
      import("./Components/Ar/ChemistryCalculator/MolarMassCalculator")
    ),
  },
  {
    // electric-charge
    path: "/calculator/ar/electric-charge/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/electric-charge")
    ),
  },
  {
    // velocity
    path: "/calculator/ar/velocity/",
    component: lazy(() => import("./Components/Ar/PhysicsCalculator/velocity")),
  },
  {
    // acceleration
    path: "/calculator/ar/acceleration/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/acceleration")
    ),
  },
  {
    // photonenergy
    path: "/calculator/ar/photonenergy/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/photonenergy")
    ),
  },
  {
    // spherevolume
    path: "/calculator/ar/sphere-volume/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/spherevolume")
    ),
  },
  {
    // frictionforce
    path: "/calculator/ar/frictionforce/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/frictionforce")
    ),
  },
  {
    // square-side-length
    path: "/calculator/ar/square-side-length/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/square-side-length")
    ),
  },
  {
    // Circle Radius
    path: "/calculator/ar/circle-radius/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/circle-radius")
    ),
  },
  {
    // Circle Calculator
    path: "/calculator/ar/circle-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/CircleCalculator")
    ),
  },
  {
    // Rounding Calculator
    path: "/calculator/ar/rounding-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/RoundingCalculator")
    ),
  },
  {
    // Squaring up Calculator
    path: "/calculator/ar/squared-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/SquaredCalculator")
    ),
  },
  {
    // LCM Calculator
    path: "/calculator/ar/lcm-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/LCMCalculator")
    ),
  },
  {
    // GCF Calculator
    path: "/calculator/ar/gcf-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/GCFCalculator")
    ),
  },
  {
    // Factors Calculator
    path: "/calculator/ar/factors-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/FactorCalculator")
    ),
  },
  {
    // Math Calculator
    path: "/calculator/ar/math-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/NormalCalculator")
    ),
  },
  {
    // Scientific Calculator
    path: "/calculator/ar/scientific-calculator/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/ScientificCalculator")
    ),
  },
  {
    // Concrete Calculator
    path: "/حاسبة_الخرسانة/",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/ConcreteCalculator")
    ),
  },
  {
    // Median
    path: "/calculator/en/median/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/MedianCalculator")
    ),
  },
  {
    // GoogleAdsene En
    path: "/calculator/en/google-adsense/",
    component: lazy(() =>
      import("./Components/En/FinancialCalculator/GoogleAdseneCalculator")
    ),
  },
  // *********** Calculators List Landing Page - Arabic ***********
  {
    // Health Calculators List
    path: "/ar/health-calculators/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/HealthCalculatorsList")
    ),
  },
  {
    // Date and time Calculators List
    path: "/ar/date-time-calculators/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/HumanCalculatorsList")
    ),
  },
  {
    // zakat Calculators List
    path: "/ar/zakat-calculators/",
    component: lazy(() =>
      import("./Components/Ar/IslamicCalculator/IslamicCalculatorList")
    ),
  },
  {
    // zakat Calculators List
    path: "/ar/women-calculators/",
    component: lazy(() =>
      import("./Components/Ar/WomenCalculator/WomenCalculatorList")
    ),
  },
  {
    // study Calculators List
    path: "/ar/study-calculators/",
    component: lazy(() =>
      import("./Components/Ar/StudyCalculator/StudyCalculatorsList")
    ),
  },
  {
    // physics Calculators List
    path: "/ar/physics-calculators/",
    component: lazy(() =>
      import("./Components/Ar/PhysicsCalculator/PhysicsCalculatorsList")
    ),
  },
  {
    // math Calculators List
    path: "/ar/math-calculators",
    component: lazy(() =>
      import("./Components/Ar/MathCalculator/MathCalculatorsList")
    ),
  },
  {
    // financial Calculators List
    path: "/ar/financial-calculators/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/FinancialCalculatorsList")
    ),
  },
  {
    // InflationCalculator
    path: "/calculator/ar/inflation/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/InflationCalculator")
    ),
  },
  {
    // AdvancedSocialSecurityCalculator
    path: "/calculator/ar/countries/SA/calculators-list/advanced-social-security/",
    component: lazy(() =>
      import(
        "./Components/Ar/FinancialCalculator/AdvancedSocialSecurityCalculator"
      )
    ),
  },
  {
    // Net Sales Calculator
    path: "/calculator/ar/net-sales-calculator/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/NetSalesCalculator")
    ),
  },
  {
    // OtherCalculator
    path: "/ar/other-calculators/",
    component: lazy(() =>
      import("./Components/Ar/OtherCalculator/OtherCalculator.js")
    ),
  },
  // *************************** English : ********************
  {
    // Landingpage English
    path: "/en/",
    component: lazy(() => import("./Components/LandingPageEN/LandingPage")),
  },
  {
    // age English
    path: "/calculator/en/agegregorian/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/AgeCalculatorGregorian.js")
    ),
  },
  {
    // age English
    path: "/calculator/en/agehijri/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/AgeCalculatorHijri.js")
    ),
  },
  {
    // birthday English
    path: "/calculator/en/birthday/",
    component: lazy(() => import("./Components/En/HumanCalculator/BirthDay")),
  },
  {
    // Gregorian age differences English
    path: "/calculator/en/gregoriandifferences",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/AgeDifferencesGregorian")
    ),
  },
  {
    // Gregorian date differences English
    path: "/calculator/en/dategregoriandifferences/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/DateDifferencesGregorian")
    ),
  },
  {
    // Gregorian age differences English
    path: "/calculator/en/hijri_to_gregorian/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/ConversionToGregorian")
    ),
  },
  {
    // Gregorian age differences English
    path: "/calculator/en/gregorian_to_hijri/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/ConversionToHijri")
    ),
  },
  {
    // Gregorian age differences English
    path: "/calculator/en/datehijridifferences/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/DateDifferencesHijri")
    ),
  },
  {
    // BMI Calculator adultes English
    path: "/calculator/en/bmiadults/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/BMICalculator")
    ),
  },
  {
    // BMI Calculator children English
    path: "/calculator/en/bmichildren/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/BMIChildCalculator")
    ),
  },
  {
    // calories Calculator English
    path: "/calculator/en/calories/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/CaloriesCalculator")
    ),
  },
  {
    // bmr Calculator English
    path: "/calculator/en/bmr/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/BMRCalculator")
    ),
  },
  {
    // idealweight Calculator English
    path: "/calculator/en/idealweight/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/IdealWeightCalculator")
    ),
  },
  {
    // bodyfat Calculator English
    path: "/calculator/en/bodyfat/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/BodyFatCalculator")
    ),
  },
  {
    // carbohydrate Calculator English
    path: "/calculator/en/carbohydrate/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/CarbohydrateCalculator")
    ),
  },
  {
    // foodenergy Calculator English
    path: "/calculator/en/foodenergy/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/FoodEnergyCalculator")
    ),
  },
  {
    // body type Calculator Arabic
    path: "/calculator/ar/body-type-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/BodyTypeCalculator")
    ),
  },
  {
    // body type Calculator English
    path: "/calculator/en/body-type-calculator/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/BodyTypeCalculator")
    ),
  },
  {
    // Daily Water Intake Calculator Arabic
    path: "/calculator/ar/daily-water-intake-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/WaterIntakeCalculator")
    ),
  },
  {
    // Daily Water Intake Calculator Arabic
    path: "/calculator/ar/vitamin_daily_dose/",
    component: lazy(() =>
      import("./Components/Ar/HealthCalculator/DailyVitaminDosage/DosageList")
    ),
  },
  {
    // Daily Water Intake Calculator Arabic
    path: "/calculator/ar/vitamin_daily_dose/:vitamin",
    component: lazy(() =>
      import(
        "./Components/Ar/HealthCalculator/DailyVitaminDosage/DosageCalculator"
      )
    ),
  },
  {
    // Daily Water Intake Calculator English
    path: "/calculator/en/daily-water-intake-calculator/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/WaterIntakeCalculator")
    ),
  },
  {
    // Money Zakat Calculator English
    path: "/calculator/en/money-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/MoneyZakatCalculator")
    ),
  },
  {
    // Gold Calculator English
    path: "/calculator/en/gold-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/GoldZakatCalculator")
    ),
  },
  {
    // Sliver Calculator English
    path: "/calculator/en/silver-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/SilverZakatCalculator")
    ),
  },
  {
    // Fruites Calculator English
    path: "/calculator/en/fruits-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/FruitsZakatCalculator")
    ),
  },
  {
    // Trade Calculator English
    path: "/calculator/en/trade-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/TradeZakatCalculator")
    ),
  },
  {
    // Animal Calculator English
    path: "/calculator/en/animals-zakat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/AnimalZakatCalculator")
    ),
  },
  {
    // Gold Karat Calculator English
    path: "/calculator/en/gold-karat/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/GoldKaratCalculator")
    ),
  },
  {
    // PeriodCalculator English
    path: "/calculator/en/period/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/PeriodCalculator")
    ),
  },
  {
    // foodenergy Calculator English
    path: "/calculator/en/conception/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/ConceptionCalculator")
    ),
  },
  {
    // OvulationCalculator English
    path: "/calculator/en/ovulation/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/OvulationCalculator")
    ),
  },
  {
    // DueDateCalculator English
    path: "/calculator/en/pregnancy-due-date/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/DueDateCalculator")
    ),
  },
  {
    // PregnancyConceptionCalculator English
    path: "/calculator/en/pregnancy-conception/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/PregnancyConceptionCalculator")
    ),
  },
  {
    // PregnancyCalculator English
    path: "/calculator/en/pregnancy/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/PregnancyCalculator")
    ),
  },
  {
    //percentage English
    path: "/calculator/en/percentage/",
    component: lazy(() => import("./Components/En/MathCalculator/Percentage")),
  },
  {
    //gpa 4 English
    path: "/calculator/en/gpa4/",
    component: lazy(() => import("./Components/En/StudyCalculator/GPA4")),
  },
  {
    //gpa 5 English
    path: "/calculator/en/gpa5/",
    component: lazy(() => import("./Components/En/StudyCalculator/GPA5")),
  },
  {
    //grade100 English
    path: "/calculator/en/grade100/",
    component: lazy(() => import("./Components/En/StudyCalculator/Grade100")),
  },
  {
    //grade100to4 English
    path: "/calculator/en/grade100to4/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/Grade100to4")
    ),
  },
  {
    //grade100to5 English
    path: "/calculator/en/grade100to5/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/Grade100to5")
    ),
  },
  {
    //gpa4to5 English
    path: "/calculator/en/gpa4to5/",
    component: lazy(() => import("./Components/En/StudyCalculator/GPA4to5")),
  },
  {
    //test grade
    path: "/calculator/en/test-grade/",
    component: lazy(() => import("./Components/En/StudyCalculator/TestGrade")),
  },
  {
    //gpa uk English
    path: "/calculator/en/gradetouk/",
    component: lazy(() => import("./Components/En/StudyCalculator/GradeToUk")),
  },
  {
    //german grade
    path: "/calculator/en/gradetogerman/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToGerman")
    ),
  },
  {
    //french grade
    path: "/calculator/en/gradetofrench/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToFrench")
    ),
  },
  {
    //canada grade
    path: "/calculator/en/gradetocanada/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToCanada")
    ),
  },
  {
    //malysia grade
    path: "/calculator/en/gradetomalaysia/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToMalysia")
    ),
  },
  {
    //turkey grade
    path: "/calculator/en/gradetoturkey/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToTurkey")
    ),
  },
  {
    //turkey grade
    path: "/calculator/en/gradetoaustralia/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeToAustralia")
    ),
  },
  {
    //GPA Planner English
    path: "/calculator/en/gpaplanner/",
    component: lazy(() => import("./Components/En/StudyCalculator/GPAPlanner")),
  },
  {
    // GPA Grade Calculator - official English
    path: "/calculator/en/gpagrade/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GradeGPACalculator")
    ),
  },
  {
    // GPA Table English
    path: "/calculator/en/gpatableconverter/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/GPATabelConverter")
    ),
  },
  {
    //about english
    path: "/en/about/",
    component: lazy(() => import("./Components/En/About")),
  },
  // Others (English)
  {
    // 5%
    path: "/calculator/en/5percent/",
    component: lazy(() => import("./Components/En/MathCalculator/5percent")),
  },
  {
    // 10%
    path: "/calculator/en/10percent/",
    component: lazy(() => import("./Components/En/MathCalculator/10percent")),
  },
  {
    // 15%
    path: "/calculator/en/15percent/",
    component: lazy(() => import("./Components/En/MathCalculator/15percent")),
  },
  {
    // 20%
    path: "/calculator/en/20percent/",
    component: lazy(() => import("./Components/En/MathCalculator/20percent")),
  },
  {
    // 25%
    path: "/calculator/en/25percent/",
    component: lazy(() => import("./Components/En/MathCalculator/25percent")),
  },
  {
    // 30%
    path: "/calculator/en/30percent/",
    component: lazy(() => import("./Components/En/MathCalculator/30percent")),
  },
  {
    // 35%
    path: "/calculator/en/35percent/",
    component: lazy(() => import("./Components/En/MathCalculator/35percent")),
  },
  {
    // 40%
    path: "/calculator/en/40percent/",
    component: lazy(() => import("./Components/En/MathCalculator/40percent")),
  },
  {
    // 75%
    path: "/calculator/en/75percent/",
    component: lazy(() => import("./Components/En/MathCalculator/75percent")),
  },
  // ******* physics calculators *******
  {
    // LensPowerCalculator
    path: "/calculator/en/lenspower/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/LensPowerCalculator")
    ),
  },
  {
    // CubicMeterCalculator
    path: "/calculator/en/cubicmeter/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/CubicMeterCalculator")
    ),
  },
  {
    // GasVolumeCalculator
    path: "/calculator/en/gasvolume/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/GasVolumeCalculator")
    ),
  },
  {
    // arrival time
    path: "/calculator/en/arrivaltime",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/ArrivalTime")
    ),
  },
  {
    // Pe-ratio
    path: "/calculator/en/pe-ratio/",
    component: lazy(() =>
      import("./Components/En/FinancialCalculator/Pe-ratio")
    ),
  },
  {
    // tank area
    path: "/calculator/en/tank-area/",
    component: lazy(() => import("./Components/En/MathCalculator/TankArea")),
  },
  {
    // Absence Percentage
    path: "/calculator/en/absence-percentage/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/AbsencePercentage")
    ),
  },
  {
    // attendance Percentage
    path: "/calculator/en/attendance-percentage/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/AttendancePercentage")
    ),
  },
  {
    //  WaterMass
    path: "/calculator/en/water-mass/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/WaterMass")
    ),
  },
  {
    //  Population Density
    path: "/calculator/en/population-density/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/PopulationDensity")
    ),
  },
  {
    //    one-fifth
    path: "/calculator/en/one-fifth/",
    component: lazy(() => import("./Components/En/MathCalculator/OneFifth")),
  },
  {
    // cylinder-circumference
    path: "/calculator/en/cylinder-circumference/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/cylinder-circumference")
    ),
  },
  {
    // iron-mass
    path: "/calculator/en/iron-mass/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/iron-mass")
    ),
  },
  {
    // electric-charge
    path: "/calculator/en/electric-charge/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/electric-charge")
    ),
  },
  {
    // velocity
    path: "/calculator/en/velocity/",
    component: lazy(() => import("./Components/En/PhysicsCalculator/velocity")),
  },
  {
    // acceleration
    path: "/calculator/en/acceleration/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/acceleration")
    ),
  },
  {
    // photonenergy
    path: "/calculator/en/photonenergy/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/photonenergy")
    ),
  },
  {
    // spherevolume
    path: "/calculator/en/sphere-volume/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/spherevolume")
    ),
  },
  {
    // frictionforce
    path: "/calculator/en/frictionforce/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/frictionforce")
    ),
  },
  {
    // square-side-length
    path: "/calculator/en/square-side-length/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/square-side-length")
    ),
  },
  {
    // circle-radius
    path: "/calculator/en/circle-radius/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/circle-radius")
    ),
  },
  // *********** Calculators List Landing Page - English ***********
  {
    // Health Calculators List
    path: "/en/health-calculators/",
    component: lazy(() =>
      import("./Components/En/HealthCalculator/HealthCalculatorsList")
    ),
  },
  {
    // Date and time Calculators List
    path: "/en/date-time-calculators/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/HumanCalculatorsList")
    ),
  },
  {
    // zakat Calculators List
    path: "/en/zakat-calculators/",
    component: lazy(() =>
      import("./Components/En/IslamicCalculator/IslamicCalculatorList")
    ),
  },
  {
    // zakat Calculators List
    path: "/en/women-calculators/",
    component: lazy(() =>
      import("./Components/En/WomenCalculator/WomenCalculatorList")
    ),
  },
  {
    // study Calculators List
    path: "/en/study-calculators/",
    component: lazy(() =>
      import("./Components/En/StudyCalculator/StudyCalculatorsList")
    ),
  },
  {
    // physics Calculators List
    path: "/en/physics-calculators/",
    component: lazy(() =>
      import("./Components/En/PhysicsCalculator/PhysicsCalculatorsList")
    ),
  },
  {
    // math Calculators List
    path: "/en/math-calculators/",
    component: lazy(() =>
      import("./Components/En/MathCalculator/MathCalculatorsList")
    ),
  },
  {
    // financial Calculators List
    path: "/en/financial-calculators/",
    component: lazy(() =>
      import("./Components/En/FinancialCalculator/FinancialCalculatorsList")
    ),
  },
  {
    // InflationCalculator
    path: "/calculator/en/inflation/",
    component: lazy(() =>
      import("./Components/En/FinancialCalculator/InflationCalculator")
    ),
  },
  {
    // Time Cal - Ar
    path: "/calculator/ar/time-calculator/",
    component: lazy(() =>
      import("./Components/Ar/HumanCalculator/TimeCalculator")
    ),
  },
  {
    // Time Cal - En
    path: "/calculator/en/time-calculator/",
    component: lazy(() =>
      import("./Components/En/HumanCalculator/TimeCalculator")
    ),
  },
  {
    // Vat Cal - ar
    path: "/calculator/ar/vat/",
    component: lazy(() =>
      import("./Components/Ar/FinancialCalculator/VATCalculator")
    ),
  },
  {
    // Vat Cal - en
    path: "/calculator/en/vat/",
    component: lazy(() =>
      import("./Components/En/FinancialCalculator/VATCalculator")
    ),
  },
  // Calculators Arabic Main page
  {
    path: "/ar/calculators/",
    component: lazy(() => import("./Components/Ar/MathCalculator/Calculators")),
  },
  // Articles Arabic Main page
  {
    path: "/articles-ar/",
    component: lazy(() => import("./Components/Ar/Articles/Articles")),
  },
  {
    // Ramadan Arabic
    path: "/articles-ar/ramadan/",
    component: lazy(() => import("./Components/Ar/Articles/Ramadan")),
  },
  {
    // eid adha Arabic
    path: "/articles-ar/eid-adha/",
    component: lazy(() => import("./Components/Ar/Articles/EidAdha")),
  },
  {
    // eid fatr Arabic
    path: "/articles-ar/eid-fatr/",
    component: lazy(() => import("./Components/Ar/Articles/EidFatr")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/shaban/",
    component: lazy(() => import("./Components/Ar/Articles/Shaban")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/motherday/",
    component: lazy(() => import("./Components/Ar/Articles/MotherDay")),
  },
  {
    // WorldCup Arabic
    path: "/articles-ar/worldcup/",
    component: lazy(() => import("./Components/Ar/Articles/WorldCup")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/olympics/",
    component: lazy(() => import("./Components/Ar/Articles/Olympics")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/asian-cup/",
    component: lazy(() => import("./Components/Ar/Articles/AsianCup")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/schooldaySA/",
    component: lazy(() => import("./Components/Ar/Articles/SABackToSchool")),
  },
  {
    // shaban Arabic
    path: "/articles-ar/hijri/",
    component: lazy(() => import("./Components/Ar/Articles/Hijri")),
  },
  // Articles Arabic
  // Articles Arabic Main page
  {
    path: "/articles-en/",
    component: lazy(() => import("./Components/En/Articles/Articles")),
  },
  {
    // Ramadan english
    path: "/articles-en/ramadan/",
    component: lazy(() => import("./Components/En/Articles/Ramadan")),
  },
  {
    // eid adha Arabic
    path: "/articles-en/eid-adha/",
    component: lazy(() => import("./Components/En/Articles/EidAdha")),
  },
  {
    // eid fatr english
    path: "/articles-en/eid-fatr/",
    component: lazy(() => import("./Components/En/Articles/EidFatr")),
  },
  // Policy
  {
    path: "/ar/privacy/",
    component: lazy(() => import("./Components/Ar/Policy")),
  },
  // All Calculators - Arabic
  {
    path: "/ar/all-calculators/",
    component: lazy(() => import("./Components/Ar/All-Calculators")),
  },
  {
    // Percentage Converter - Arabic

    path: "/calculator/ar/percentage_converter/:percentage/",
    component: lazy(() =>
      import(
        "./Components/Ar/MathCalculator/PercentageConverter/PercentageConverter.js"
      )
    ),
  },
  {
    // Percentage Converter - List - Arabic

    path: "/calculator/ar/percentage_converter/percentage_list/",
    component: lazy(() =>
      import(
        "./Components/Ar/MathCalculator/PercentageConverter/PercentageList.js"
      )
    ),
  },
  {
    // Percentage Converter - English

    path: "/calculator/en/percentage_converter/:percentage/",
    component: lazy(() =>
      import(
        "./Components/En/MathCalculator/PercentageConverter/PercentageConverter.js"
      )
    ),
  },
  {
    // Percentage Converter - List - Eng

    path: "/calculator/en/percentage_converter/percentage_list/",
    component: lazy(() =>
      import(
        "./Components/En/MathCalculator/PercentageConverter/PercentageList.js"
      )
    ),
  },

  {
    //  Units List
    path: "/ar/unit_converter/",
    component: lazy(() => import("./Components/Ar/UnitConverter/UnitsList.js")),
  },
  {
    // Length Units List
    path: "/ar/unit_converter/length",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/LengthUnitList.js")
    ),
  },
  {
    // Length Converter
    path: "/calculator/ar/unit_converter/length/:fromLengthUnit/:toLengthUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/LengthCalculator.js")
    ),
  },
  {
    // Weight Units List
    path: "/ar/unit_converter/weight",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/WeightUnitList.js")
    ),
  },
  {
    // Weight Converter
    path: "/calculator/ar/unit_converter/weight/:fromWeightUnit/:toWeightUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/WeightConverter.js")
    ),
  },
  {
    // Temperature Units List
    path: "/ar/unit_converter/temperature",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/TemperatureUnitList.js")
    ),
  },
  {
    // Temperature Converter
    path: "/calculator/ar/unit_converter/temperature/:fromTemperatureUnit/:toTemperatureUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/TemperatureConverter.js")
    ),
  },
  {
    // Area Units List
    path: "/ar/unit_converter/area",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/AreaUnitList.js")
    ),
  },
  {
    // Area Converter
    path: "/calculator/ar/unit_converter/area/:fromAreaUnit/:toAreaUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/AreaConverter.js")
    ),
  },
  {
    // Volume Units List
    path: "/ar/unit_converter/volume",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/VolumeUnitList.js")
    ),
  },
  {
    // Volume Converter
    path: "/calculator/ar/unit_converter/volume/:fromVolumeUnit/:toVolumeUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/VolumeConverter.js")
    ),
  },
  {
    // Time Units List
    path: "/ar/unit_converter/time",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/TimeUnitList.js")
    ),
  },
  {
    // Time Converter
    path: "/calculator/ar/unit_converter/time/:fromTimeUnit/:toTimeUnit/",
    component: lazy(() =>
      import("./Components/Ar/UnitConverter/TimeConverter.js")
    ),
  },
  {
    // Currency Converter

    path: "/calculator/ar/currency_converter/:fromCurrency/:toCurrency/",
    component: lazy(() =>
      import(
        "./Components/Ar/FinancialCalculator/Currency_converter/CurrencyConverter.js"
      )
    ),
  },
  {
    // Currency

    path: "/calculator/ar/currency_list/",
    component: lazy(() =>
      import(
        "./Components/Ar/FinancialCalculator/Currency_converter/Currency.js"
      )
    ),
  },
];

function App() {
  // Use the Router component to wrap everything
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const currentLanguage = location.pathname.includes("/en/") ? "en" : "ar";

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {currentLanguage === "ar" ? <Header /> : <HeaderEN />}
      <div className="container">
        <Routes>
          {calculatorRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}

          <Route path="*" element={<LandingPage />} />
          <Route path="/ar/" element={<LandingPage />} />
          <Route path="/en/" element={<LandingPageEN />} />
          <Route path="/ar/privacy/" element={<Privacy />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
